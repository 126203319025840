<template>
    <div>
        <fieldset class="mt-2">
            <legend class="text-blue">
                {{ $t("loanDisbursement.disbursementInfo") }}
            </legend>
            <div class="row">
                <div class="col-md-6">
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                        >
                            {{ $t("loanDisbursement.disbursementDate") }}
                        </label>
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <DatePicker
                                    :value="model.disbursement_date"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeDisbursementDate"
                                    disabled
                                ></DatePicker>
                                <input
                                    v-model="model.voucher_number"
                                    class="form-control form-control-sm"
                                    :placeholder="
                                        $t(
                                            'loanDisbursement.voucherNumberOptional'
                                        )
                                    "
                                    :class="{
                                        'is-invalid': errors.has(
                                            'voucher_number'
                                        )
                                    }"
                                />
                            </div>
                            <div
                                class="text-danger"
                                v-if="errors.has('disbursement_date')"
                            >
                                {{ errors.first("disbursement_date") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                        >
                            {{ $t("loanDisbursement.customerName") }}
                        </label>
                        <div class="col-md-7">
                            <Select
                                v-model="model.customer_id"
                                filterable
                                :remote-method="loadCustomer"
                                :loading="customerLoading"
                                :placeholder="
                                    $t('loanDisbursement.searchCustomer')
                                "
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'customer_id'
                                    )
                                }"
                                disabled
                            >
                                <Option
                                    v-for="(option, index) in customers"
                                    :value="option.customer_id"
                                    :key="index"
                                >
                                    {{ option.customer_name_en }}
                                </Option>
                            </Select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('customer_id')"
                            >
                                {{ errors.first("customer_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                            >{{ $t("loanDisbursement.loanType") }}</label
                        >
                        <div class="col-md-7">
                            <select
                                v-model.number="model.loan_type_id"
                                class="form-select form-select-sm"
                                :class="{
                                    'is-invalid': errors.has('loan_type_id')
                                }"
                                @change="onChangeLoanType"
                                disabled
                            >
                                <option
                                    :value="null"
                                    disabled
                                    selected
                                    style="display: none;"
                                    >{{ $t("select") }}</option
                                >
                                <option
                                    v-for="(option, index) in loanTypes"
                                    :value="option.loan_type_id"
                                    :key="index"
                                >
                                    {{ option.loan_type_en }}
                                </option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('loan_type_id')"
                            >
                                {{ errors.first("loan_type_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                            >{{ $t("loanDisbursement.currency") }}</label
                        >
                        <div class="col-md-7 tw-whitespace-nowrap">
                            <RadioGroup
                                v-model="model.currency_id"
                                size="small"
                            >
                                <Radio
                                    class="mt-1"
                                    :label="c.currency_id"
                                    border
                                    v-for="(c, index) in currencies"
                                    :key="index"
                                    disabled
                                >
                                    {{ c.currency_code }}
                                </Radio>
                            </RadioGroup>
                            <div
                                class="text-danger"
                                v-if="errors.has('currency_id')"
                            >
                                {{ errors.first("currency_id") }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="row"
                        :class="{
                            'mb-2': !loanCurrency
                        }"
                    >
                        <label
                            class="form-label col-form-label col-md-5 required"
                            >{{ $t("loanDisbursement.loanAmount") }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <Poptip trigger="focus">
                                    <Input
                                        v-model.number="model.loan_amount"
                                        :placeholder="
                                            $t('loanDisbursement.enterNumber')
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'loan_amount'
                                            )
                                        }"
                                        ref="loan_amount"
                                        disabled
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </Input>
                                    <div slot="content">
                                        {{
                                            model.loan_amount
                                                ? formatNumber(
                                                      model.loan_amount,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "loanDisbursement.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                                <label
                                    class="form-label col-form-label required"
                                >
                                    <small style="font-size:10px">{{
                                        $t("loanDisbursement.interestRate")
                                    }}</small></label
                                >

                                <InputNumber
                                    :max="100"
                                    v-model="model.interest_rate"
                                    :formatter="value => `${value}%`"
                                    :parser="value => value.replace('%', '')"
                                    :placeholder="
                                        $t('loanDisbursement.interestRate')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'interest_rate'
                                        )
                                    }"
                                    style="width: 30%"
                                    disabled
                                ></InputNumber>
                            </div>
                            <div
                                class="text-danger tw-text-right"
                                v-if="errors.has('interest_rate')"
                            >
                                {{ errors.first("interest_rate") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2" v-if="loanCurrency">
                        <label
                            class="form-label col-form-label col-md-5"
                        ></label>
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <span class="badge bg-success rounded-pill"
                                    >Min amount
                                    {{
                                        formatNumber(
                                            loanCurrency.min_size,
                                            model.currency_id
                                        )
                                    }}
                                    Max amount
                                    {{
                                        formatNumber(
                                            loanCurrency.max_size,
                                            model.currency_id
                                        )
                                    }}</span
                                >
                                <span class="badge bg-blue rounded-pill"
                                    >Min rate {{ loanCurrency.min_rate }} Max
                                    rate {{ loanCurrency.max_rate }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <label
                            class="form-label col-form-label col-md-5 required"
                            >{{ $t("loanDisbursement.co") }}</label
                        >
                        <div class="col-md-7">
                            <Select
                                v-model="model.co_id"
                                filterable
                                :remote-method="loadEmployee"
                                :loading="employeeLoading"
                                :placeholder="
                                    $t('loanDisbursement.searchEmployee')
                                "
                                :class="{
                                    'ivu-form-item-error': errors.has('co_id')
                                }"
                                disabled
                            >
                                <Option
                                    v-for="(option, index) in employees"
                                    :value="option.employee_id"
                                    :key="index"
                                >
                                    {{ option.employee_name_en }}
                                </Option>
                            </Select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('co_id')"
                            >
                                {{ errors.first("co_id") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                            >{{ $t("loanDisbursement.paymentMethod") }}</label
                        >
                        <div class="col-md-7">
                            <select
                                v-model.number="model.repayment_method_id"
                                class="form-select form-select-sm"
                                :class="{
                                    'is-invalid': errors.has(
                                        'repayment_method_id'
                                    )
                                }"
                                @change="
                                    () =>
                                        (model.semi_baloon_effective_cycle = model.amount_per_cycle = null)
                                "
                            >
                                <option
                                    :value="null"
                                    disabled
                                    selected
                                    style="display: none;"
                                    >{{ $t("select") }}</option
                                >
                                <option
                                    v-for="(option, index) in repaymentMethods"
                                    :value="option.repayment_method_id"
                                    :key="index"
                                >
                                    {{ option.method_name_en }}
                                    {{
                                        option.method_name_kh
                                            ? "| " + option.method_name_kh
                                            : ""
                                    }}
                                </option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('repayment_method_id')"
                            >
                                {{ errors.first("repayment_method_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                        >
                            {{ $t("loanDisbursement.inerval") }}
                        </label>
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <InputNumber
                                    v-model="model.payment_interval"
                                    :placeholder="
                                        $t('loanDisbursement.inerval')
                                    "
                                    :min="1"
                                    disabled
                                ></InputNumber>
                                <label
                                    class="form-label col-form-label required"
                                >
                                    <small>{{
                                        $t("loanDisbursement.firstInstallment")
                                    }}</small>
                                </label>
                                <DatePicker
                                    :value="model.first_installment_date"
                                    style="width:150px"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeFirstInstallmentDate"
                                    disabled
                                ></DatePicker>
                            </div>
                            <div
                                class="text-danger tw-text-right"
                                v-if="errors.has('first_installment_date')"
                            >
                                {{ errors.first("first_installment_date") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                            >{{ $t("loanDisbursement.installmentType") }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-whitespace-nowrap tw-space-x-1 tw-justify-between tw-items-center"
                            >
                                <RadioGroup
                                    v-model="model.installment_type_id"
                                    size="small"
                                    type="button"
                                >
                                    <Radio
                                        class="mt-1"
                                        :label="c.id"
                                        v-for="(c, index) in installmentTypes"
                                        :key="index"
                                        disabled
                                    >
                                        {{ c.label }}
                                    </Radio>
                                </RadioGroup>
                                <div>
                                    <ts-checkbox
                                        v-model="model.is_skip_saturday"
                                        class="tw-text-xs"
                                        disabled
                                        >{{
                                            $t("loanDisbursement.skipSaturday")
                                        }}</ts-checkbox
                                    >
                                    <ts-checkbox
                                        v-model="model.is_skip_sunday"
                                        class="tw-text-xs"
                                        disabled
                                        >{{
                                            $t("loanDisbursement.skipSunday")
                                        }}</ts-checkbox
                                    >
                                </div>
                            </div>
                            <div
                                class="text-danger"
                                v-if="errors.has('installment_type_id')"
                            >
                                {{ errors.first("installment_type_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5 required"
                            >{{
                                $t("loanDisbursement.installmentTypeDuration")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap tw-items-center"
                            >
                                <InputNumber
                                    v-model.number="model.number_of_cycle"
                                    :min="1"
                                    disabled
                                ></InputNumber>
                                <label class="form-label col-form-label">
                                    <small style="font-size:10px">{{
                                        $t(
                                            "loanDisbursement.allowPayOffOnCycle"
                                        )
                                    }}</small>
                                </label>
                                <InputNumber
                                    v-model.number="
                                        model.allow_pay_off_on_cycle
                                    "
                                    :min="0"
                                    style="width:150px"
                                ></InputNumber>
                            </div>

                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap tw-items-center"
                            >
                                <div
                                    class="text-danger"
                                    v-if="errors.has('number_of_cycle')"
                                >
                                    {{ errors.first("number_of_cycle") }}
                                </div>
                                <div
                                    class="text-danger"
                                    v-if="errors.has('allow_pay_off_on_cycle')"
                                >
                                    {{ errors.first("allow_pay_off_on_cycle") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label
                            class="form-label col-form-label col-md-5"
                            :class="{
                                required: model.repayment_method_id == 3
                            }"
                            >{{
                                $t("loanDisbursement.semiBaloonEffectiveCycle")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <InputNumber
                                    v-model="model.semi_baloon_effective_cycle"
                                    :min="0"
                                    :disabled="model.repayment_method_id != 3"
                                ></InputNumber>
                            </div>
                            <div
                                class="text-danger"
                                v-if="errors.has('semi_baloon_effective_cycle')"
                            >
                                {{
                                    errors.first("semi_baloon_effective_cycle")
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <label class="form-label col-form-label col-md-5">{{
                            $t("loanDisbursement.remark")
                        }}</label>
                        <div class="col-md-7">
                            <textarea
                                v-model="model.remarks"
                                class="form-control"
                                rows="1"
                                :placeholder="$t('loanDisbursement.remarks')"
                            ></textarea>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('emp_type_id')"
                            >
                                {{ errors.first("emp_type_id") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { sortBy, debounce } from "lodash";
import moment from "moment";

export default {
    name: "disbursementProfile",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            customerLoading: false,
            employeeLoading: false,
            loanCurrency: null,
            errors: new Errors(),
            installment_types: {
                daily: {
                    id: 1,
                    label: "Daily"
                },
                weekly: {
                    id: 2,
                    label: "Weekly"
                },
                monthly: {
                    id: 3,
                    label: "Monthly"
                }
            }
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("creditOperation/loanDisbursement", [
            "edit_data",
            "employees",
            "customers",
            "currencies",
            "loanTypes",
            "repaymentMethods"
        ]),
        ...mapGetters(["formatNumber"]),
        installmentTypes() {
            let newObj = [];

            let loanType = this.loanTypes.find(
                l => l.loan_type_id == this.model.loan_type_id
            );

            if (loanType) {
                let { daily, monthly, weekly } = loanType;

                if (daily) {
                    newObj.push(this.installment_types["daily"]);
                }
                if (monthly) {
                    newObj.push(this.installment_types["monthly"]);
                }
                if (weekly) {
                    newObj.push(this.installment_types["weekly"]);
                }

                return sortBy(newObj, ["id"]);
            }

            return Object.values(this.installment_types);
        }
    },
    methods: {
        ...mapActions("creditOperation/loanDisbursement", [
            "getEmployees",
            "getCustomer"
        ]),
        loadEmployee: debounce(function(query) {
            this.getEmployees({ search: query });
        }, 1000),
        loadCustomer: debounce(function(query) {
            this.getCustomer({ search: query });
        }, 1000),
        onChangeDisbursementDate(date) {
            this.model.disbursement_date = date;
            this.model.first_installment_date = moment(date, "DD-MM-YYYY")
                .add(1, "days")
                .format("DD-MM-YYYY");
        },
        onChangeFirstInstallmentDate(date) {
            this.model.first_installment_date = date;
        },
        onChangeLoanType() {
            this.model.installment_type_id = null;
            let loan_type = this.loanTypes.find(
                l => l.loan_type_id == this.model.loan_type_id
            );
            if (loan_type) {
                this.model.number_of_cycle = loan_type.default_number_of_cycle;
                this.model.repayment_method_id =
                    loan_type.default_repayment_method_id;
                this.model.currency_id = loan_type.default_currency_id;
                this.model.interest_rate = loan_type.default_interest_rate;
                this.model.rescue_fee = loan_type.rescue_fee;
                this.model.is_skip_saturday = loan_type.is_skip_saturday;
                this.model.is_skip_sunday = loan_type.is_skip_sunday;
                this.$nextTick(function() {
                    this.$refs.loan_amount.focus();
                });
            }
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        },
        "model.loan_type_id": function(val) {
            if (val) {
                let loan_type = this.loanTypes.find(l => l.loan_type_id == val);
                if (loan_type) {
                    this.loanCurrency = loan_type.loan_currency;
                }
            }
        },
        installmentTypes: function(val) {
            if (val.length === 1) {
                this.model.installment_type_id = val[0].id;
            }
        }
    }
};
</script>
